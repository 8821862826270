import clone from 'lodash/clone';

import { getAllEl } from '@utils/getEl';

export const FOCUSABLE_SELECTORS = 'a,button,input:not([type="hidden"]),select,textarea,[tabindex]';

export const FOCUSABLE_SELECTORS_ARRAY = FOCUSABLE_SELECTORS.split(',');

export const FOCUSABLE_NOT_DISABLED_SELECTORS = FOCUSABLE_SELECTORS_ARRAY.map(
    (selector) => `${selector}:not([disabled])`
).join(',');

export const FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS = FOCUSABLE_SELECTORS_ARRAY.map(
    (selector) => `${selector}:not([disabled]):not([tabindex="-1"])`
).join(',');

const isElementInvisible = (element: HTMLElement, elementStyle: CSSStyleDeclaration): boolean => {
    return (
        elementStyle.height == '0px' ||
        elementStyle.display == 'none' ||
        (elementStyle.opacity == '0' && element.tabIndex < 0) ||
        elementStyle.visibility == 'hidden' ||
        elementStyle.clipPath == 'circle(0px at 50% 50%)' ||
        elementStyle.transform == 'scale(0)' ||
        element.hasAttribute('hidden')
    );
};

export const isVisible = (element: HTMLElement) => {
    const elementStyle = window.getComputedStyle(element);
    // Particular cases when the element is not visible at all
    if (isElementInvisible(element, elementStyle)) {
        return false;
    }

    const elementRect = element.getBoundingClientRect();

    // Overlapping strict check
    const baseElementLeft = elementRect.left;
    const baseElementTop = elementRect.top;

    const elementFromStartingPoint = document.elementFromPoint(baseElementLeft, baseElementTop);

    if (elementFromStartingPoint != null && !element.isSameNode(elementFromStartingPoint)) {
        const elementZIndex = elementStyle.zIndex;
        const elementOverlappingZIndex = window.getComputedStyle(elementFromStartingPoint).zIndex;
        if (Number(elementZIndex) < Number(elementOverlappingZIndex)) {
            return false;
        }

        if (elementZIndex === '' && elementOverlappingZIndex === '') {
            /*
             * If two positioned elements overlap without a z-index specified, the element
             * positioned last in the HTML code will be shown on top
             */
            if (element.compareDocumentPosition(elementFromStartingPoint) & Node.DOCUMENT_POSITION_FOLLOWING) {
                return false;
            }
        }
    }
    return true;
};

const getMaxXScrollableContainer = (containerElement: HTMLElement) => {
    const maxXScrollableContainerElement = containerElement.matches('.js-OverlayScrollbar .os-viewport')
        ? containerElement
        : getAllEl(containerElement, '.js-OverlayScrollbar .os-viewport', HTMLElement).sort((overlayA, overlayB) => {
              return overlayB.scrollWidth - overlayA.scrollWidth;
          })[0];

    return maxXScrollableContainerElement ?? containerElement;
};

export const isReachableWithinContainer = (
    element: HTMLElement | null,
    containerElement: HTMLElement | null,
    fixXScroll = false
) => {
    if (!element || !containerElement || !isVisible(element)) {
        return false;
    }

    const fixedXScrollContainer = fixXScroll ? getMaxXScrollableContainer(containerElement) : containerElement;
    const elementRect = element.getBoundingClientRect();
    const fixedXScrollContainerRect = fixedXScrollContainer.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();
    const containerLeftOffset = fixedXScrollContainerRect.left - fixedXScrollContainer.scrollLeft;
    const containerTopOffset = containerRect.top - containerElement.scrollTop;

    return (
        Math.floor(containerLeftOffset) < Math.floor(elementRect.right) &&
        Math.floor(containerLeftOffset + fixedXScrollContainer.scrollWidth) > Math.floor(elementRect.left) &&
        Math.floor(containerTopOffset) < Math.floor(elementRect.bottom) &&
        Math.floor(containerTopOffset + containerElement.scrollHeight) > Math.floor(elementRect.top)
    );
};

export const isFocusable = (focusableElement: HTMLElement): boolean =>
    focusableElement.offsetParent !== null &&
    // prevent sr-only element to be focused
    focusableElement.offsetHeight > 1 &&
    focusableElement.offsetWidth > 1 &&
    isVisible(focusableElement);

export const getFocusableElements = (parentElement: HTMLElement | null): Array<HTMLElement> =>
    getAllEl(parentElement, FOCUSABLE_SELECTORS, HTMLElement);

export const getFocusableNotDisabledElements = (parentElement: HTMLElement | null): Array<HTMLElement> =>
    getAllEl(parentElement, FOCUSABLE_NOT_DISABLED_SELECTORS, HTMLElement);

export const getFocusableNotDisabledIndexableElements = (parentElement: HTMLElement | null): Array<HTMLElement> =>
    getAllEl(parentElement, FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS, HTMLElement);

export const sortByLogicalFocusOrder = (focusableElements: Array<HTMLElement>): Array<HTMLElement> => {
    return clone(focusableElements).sort((focusableElementA: HTMLElement, focusableElementB: HTMLElement) => {
        const { y: yA, height: heightA, x: leftA } = focusableElementA.getBoundingClientRect();
        const { y: yB, height: heightB, x: leftB } = focusableElementB.getBoundingClientRect();
        const baseLineA = yA + heightA / 2;
        const baseLineB = yB + heightB / 2;
        const maxHeight = Math.max(heightA, heightB);
        if (baseLineA - baseLineB > maxHeight) {
            return 1;
        } else if (baseLineB - baseLineA > maxHeight) {
            return -1;
        } else if (leftA > leftB) {
            return 1;
        } else if (leftB > leftA) {
            return -1;
        }
        return 0;
    });
};

const findLastAbove = (element: HTMLElement | null, selector: string): HTMLElement | null => {
    if (!element?.parentElement) {
        return null;
    }
    const siblings: Array<HTMLElement> = Array.from(element.parentElement.children) as Array<HTMLElement>;
    const previousSiblings = siblings.splice(0, siblings.indexOf(element)).reverse();

    for (const previousSibling of previousSiblings) {
        if (previousSibling.matches(selector) && isFocusable(previousSibling)) {
            return previousSibling;
        }
        const elements = sortByLogicalFocusOrder(getAllEl(previousSibling, selector, HTMLElement)).filter(isFocusable);
        if (elements.length) {
            return elements[elements.length - 1];
        }
    }
    return findLastAbove(element.parentElement, selector);
};

const findFirstBelow = (element: HTMLElement | null, selector: string): HTMLElement | null => {
    if (!element?.parentElement) {
        return null;
    }
    const siblings: Array<HTMLElement> = Array.from(element.parentElement.children) as Array<HTMLElement>;
    const nextSiblings = siblings.splice(siblings.indexOf(element) + 1);

    for (const nextSibling of nextSiblings) {
        if (nextSibling.matches(selector) && isFocusable(nextSibling as HTMLElement)) {
            return nextSibling;
        }
        const elements = sortByLogicalFocusOrder(getAllEl(nextSibling, selector, HTMLElement)).filter(isFocusable);
        if (elements.length) {
            return elements[0];
        }
    }
    return findFirstBelow(element.parentElement, selector);
};

export const getNextFocusableElement = (element: HTMLElement | null) =>
    findFirstBelow(element, FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS);

export const getPreviousFocusableElement = (element: HTMLElement | null) =>
    findLastAbove(element, FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS);
