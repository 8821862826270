// Some pages urls do not coincide with names required by GTM tracking,
// so we provide mapping to find GTM tracking name by current location.href

const urlToGtmMapping = {
    dashboard: 'account-dashboard',
    home: 'homepage',
    'find-a-voyage': 'itinerary-results',
    'choose-a-cabin': 'choose-cabin',
    'upcoming-voyages': 'account-upcoming-voyages',
};

interface Paths {
    currentPage: string;
    pagePaths: Array<string>;
}

const getPaths = (): Paths => {
    const { pathname } = window.location;

    const sanitizedPath = pathname.startsWith('/') ? pathname.slice(1) : pathname;

    const pagePaths = sanitizedPath.split('/');

    const currentPage = pagePaths[pagePaths.length - 1] || 'home';

    return { currentPage, pagePaths };
};

const getPage = (): string => {
    const { currentPage, pagePaths } = getPaths();

    return urlToGtmMapping[currentPage] || pagePaths.join('|');
};

export const getMappedPage = (): string | undefined => {
    const { currentPage } = getPaths();

    return urlToGtmMapping[currentPage];
};

export default getPage;
