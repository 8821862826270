// TODO: move logic to websrc/js/common/components/global-navigation.js

import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { matchMinWidth, VIEWPORT_MIN } from '../../../common/js/utils/match-media';

import configs from '../config';
import { getAllEl, getClosestEl, getEl } from '../../../cms/js/utils/getEl';
import { KeyControl } from './global-navigation/key-control';
import { checkAndroid } from '../../common/libs/check-user-agent';
import {
    FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS,
    getNextFocusableElement,
    isFocusable,
} from '../../common/utils/focus';

export const CLASSNAMES = {
    // PAST_HERO: "is-past-hero",
    IS_HERO: '-hero',
    ACTIVE: 'is-nav-active', // means a menu is opened or not for mobile and tablet. it is added/removed immediately on toggle button click.
    OPEN: 'is-nav-open', // the same as ACTIVE but it is added/removed with a delay.
    ALLOW_CLICK_THROUGH: '-allowClickThrough',
    // Force nav into condensed state when timer is up
    NAV_SUBNAV_OPEN: '-subNavOpen',
    SUBNAV_OPEN: '-open',
    IS_SUBTAB_ACTIVE: 'is-sub-tab-active',

    NAV: '.js-GlobalNavigation',
    ITEM: '.js-nav-bar2__item',
    ITEM_LINK: '.js-nav-bar2__link',
    SUBNAV: '.nav-bar2__subNav',
    SUBNAV_ITEM: '.nav-bar2__subNav-item',
    SUBNAV_LINK: '.js-GlobalNavigation__subLink',
};
const ANIMATION_DURATION = 400;

class SubNavs {
    constructor(el, globalNavEl) {
        this.$el = el;
        this.$subNavs = [...this.$el.querySelectorAll('li ul')];
        this.$openedSubNav = null;
        this.globalNavEl = globalNavEl;
        this.keyControl = new KeyControl(this.triggerSubNavClose, this.triggerSubNavOpen, CLASSNAMES);
        this.keyControl.spyOn(getEl(globalNavEl, '#global-navigation-menubar'));
        if (this.$subNavs.length) {
            this.$subNavs.forEach((subNav) => {
                const $anchorEl = subNav.parentNode.querySelector('button');
                const $achorParent = $anchorEl.parentNode;

                $anchorEl.addEventListener('click', this.onItemClick);

                // When mouse is over the anchor, open the sub nav
                $anchorEl.addEventListener('mouseover', this.runOnDesktop.bind(this, this.triggerSubNavOpen));
                // Close the sub nav when the mouse leaves
                $achorParent.parentNode.addEventListener(
                    'mouseleave',
                    this.runOnDesktop.bind(this, this.triggerSubNavClose)
                );
            });
        }
    }

    runOnDesktop = (action, args) => {
        window.innerWidth < 992 ||
            window.navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i) ||
            action(args);
    };

    toggleSubNav = (event) => {
        const isOpened = this.isSubNavOpen(event.currentTarget.parentElement);
        const action = isOpened ? 'triggerSubNavClose' : 'triggerSubNavOpen';
        this[action]?.(event);
    };

    triggerSubNavOpen = (event) => {
        const $subNav = getClosestEl(event.target, CLASSNAMES.ITEM);
        // Open subnav
        $subNav.classList.add(CLASSNAMES.SUBNAV_OPEN);
        getEl($subNav, CLASSNAMES.ITEM_LINK)?.setAttribute('aria-expanded', 'true');
        // Expand nav bar
        this.expandNav();
        // Close other open subnav (if any is open)
        this.closeOpenedSubNav($subNav);
        this.$openedSubNav = $subNav;
    };

    triggerSubNavClose = (event) => {
        this.closeOpenedSubNav();
        this.collapseNav();
    };

    onItemClick = (event) => {
        this.toggleSubNav(event);
        event.preventDefault();
    };

    closeOpenedSubNav(excludedSubNavEl) {
        if (!this.$openedSubNav || this.$openedSubNav === excludedSubNavEl) {
            return;
        }
        getEl(this.$openedSubNav, CLASSNAMES.ITEM_LINK)?.setAttribute('aria-expanded', 'false');
        this.$openedSubNav.classList.remove(CLASSNAMES.SUBNAV_OPEN);
        this.globalNavEl?.classList.add('-subNavOpened');
        this.$openedSubNav = null;
    }

    expandNav() {
        this.$el.classList.add(CLASSNAMES.NAV_SUBNAV_OPEN);
        this.globalNavEl?.classList.add('-subNavOpened');
    }

    collapseNav() {
        this.$el.classList.remove(CLASSNAMES.NAV_SUBNAV_OPEN);
        this.globalNavEl?.classList.remove('-subNavOpened');
    }

    isSubNavOpen(el) {
        return el.classList.contains(CLASSNAMES.SUBNAV_OPEN);
    }
}

export class Navbar {
    constructor(el, stateChangeListener) {
        this.syntheticFocus = false;
        this.focusedByMouse = false;
        this.el = el;
        this.stateChangeListener = stateChangeListener;
        this.$mainWrapper = document.querySelector('.mainWrapper');
        this.toggleBtn = document.getElementsByClassName('js-nav-bar2__toggle')[0]; // eslint-disable-line prefer-destructuring
        this.bookButton = this.el.querySelector('.js-BookButton');
        this.isVisible = false;
        this.globalNavEl = document.querySelector(CLASSNAMES.NAV);

        this.handleScrollThrottled = throttle(this.handleScroll.bind(this), 200);
        this.globalNavEl.addEventListener('keydown', this.onKeyDown);
        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('focusin', this.onFocusIn);
        window.addEventListener('scroll', this.handleScrollThrottled);
        if (this.toggleBtn) {
            this.toggleBtn.addEventListener('click', (event) => {
                event.preventDefault();
                this.toggleGlobalMenu();
            });
            const closeBtn = this.toggleBtn.querySelector('.nav-bar2__icon-close');
            if (closeBtn) {
                window.addEventListener('resize', debounce(this.handleResize.bind(this, closeBtn), 200));
            }
        }

        // Check if there is a hero, track position if so
        if (this.el.dataset.heroClassname) {
            this.hero = document.getElementsByClassName(this.el.dataset.heroClassname)[0]; // eslint-disable-line prefer-destructuring
            this.el.classList.add(CLASSNAMES.IS_HERO);
        }

        this.handleScroll();

        this.subNavs = new SubNavs(this.el, this.globalNavEl); // eslint-disable-line no-new

        this.logo = getEl(this.globalNavEl, '.nav-bar2__logo');
        this.accountDropdown = getEl(this.globalNavEl, '.js-BookingAccountDropdown2');
        // Ensure next focus after Logo is a subnav link
        this.logo.addEventListener('blur', (event) => {
            if (event.relatedTarget === this.accountDropdown && matchMinWidth(VIEWPORT_MIN.DESKTOP).matches) {
                event.preventDefault();
                this.subNavs.keyControl.focusFirstMenuItem();
            }
        });
    }

    handleResize(closeBtn) {
        // Apparently resized by keyboard on Android
        if (checkAndroid() && window.visualViewport.height < window.screen.height - 200) {
            return;
        }
        const rect = closeBtn.getBoundingClientRect();
        if (rect.width <= 0 && rect.height <= 0) {
            this.setIsActive(false);
        }
    }

    toggleGlobalMenu = (isOpened) => {
        clearTimeout(this.openTimeout);
        this.isVisible = isOpened ?? !this.isVisible;
        this.openTimeout = setTimeout(this.setIsOpen.bind(this, this.isVisible), ANIMATION_DURATION);
        this.setIsActive(this.isVisible);
    };

    /** That's a hack until we rewrite the global navigation. */
    onFocusIn = (event) => {
        if (this.focusedByMouse) {
            return;
        }
        const isDesktop = matchMinWidth(VIEWPORT_MIN.DESKTOP).matches;
        const fromNav = getClosestEl(event.relatedTarget, CLASSNAMES.NAV);
        const toNav = getClosestEl(event.target, CLASSNAMES.NAV);

        /** That's crucial condition to avoid falling in the loop! */
        if (this.syntheticFocus || (!fromNav && !toNav)) {
            this.syntheticFocus = false;
            event.target.focus();
            return;
        }

        if (isDesktop) {
            const fromLogo = getClosestEl(event.relatedTarget, '.nav-bar2__logo');
            const toBook =
                getClosestEl(event.target, '.js-BookingAccountDropdown2') ??
                getClosestEl(event.target, '.js-BookButton');
            if (fromLogo && toBook) {
                this.syntheticFocus = true;
                this.subNavs.keyControl.focusFirstMenuItem();
                return;
            }

            const sibling = getNextFocusableElement(this.globalNavEl);
            const toMenu = getClosestEl(event.target, '#global-navigation-menubar');

            if (sibling === event.relatedTarget && toMenu) {
                this.syntheticFocus = true;
                (this.bookButton ?? this.accountDropdown)?.focus();
                return;
            }

            const fromMenu = getClosestEl(event.relatedTarget, '#global-navigation-menubar');

            if ((fromMenu && toBook) || (!fromNav && event.relatedTarget)) {
                this.subNavs.triggerSubNavClose();
                this.syntheticFocus = true;
                this.logo?.focus();
                return;
            }

            if (fromMenu && !toNav) {
                this.subNavs.triggerSubNavClose();
                this.syntheticFocus = true;
                const btn = this.accountDropdown ?? getEl(this.globalNavEl, '.js-BookButton');
                btn?.focus();
                return;
            }

            const fromBook =
                getClosestEl(event.relatedTarget, '.js-BookingAccountDropdown2') ??
                getClosestEl(event.relatedTarget, '.js-BookButton');
            const toLogo = getClosestEl(event.target, '.nav-bar2__logo');
            if (fromBook && toLogo) {
                this.syntheticFocus = true;
                this.subNavs.keyControl.focusLastMenuItem();
                return;
            }

            if (fromBook && toMenu) {
                this.syntheticFocus = true;
                sibling?.focus();
                return;
            }
        } else {
            if (!this.isVisible) {
                return;
            }

            const fromLogo = getClosestEl(event.relatedTarget, '.nav-bar2__logo');
            const toAccount = getClosestEl(event.target, '.js-BookingAccountDropdown2');
            if (fromLogo && toAccount) {
                this.syntheticFocus = true;
                getEl(this.globalNavEl, '.js-BookButton')?.focus();
                return;
            }

            const fromMenu = getClosestEl(event.relatedTarget, '#global-navigation-menubar');
            if (fromMenu && !toNav) {
                this.syntheticFocus = true;
                this.accountDropdown?.focus();
                return;
            }

            const fromAccount = getClosestEl(event.relatedTarget, '.js-BookingAccountDropdown2');
            const toBook = getClosestEl(event.target, '.js-BookButton');
            if (fromAccount && toBook) {
                this.syntheticFocus = true;
                this.toggleBtn?.focus();
                return;
            }

            const toLogo = getClosestEl(event.target, '.nav-bar2__logo');
            if (fromAccount && toLogo) {
                this.syntheticFocus = true;
                this.subNavs.keyControl.focusFirstMenuItem();
                return;
            }

            const fromBook = getClosestEl(event.relatedTarget, '.js-BookButton');
            if (fromBook && toAccount) {
                this.syntheticFocus = true;
                this.logo?.focus();
                return;
            }

            const fromToggle = getClosestEl(event.relatedTarget, '.js-nav-bar2__toggle');
            if (fromToggle && !toNav) {
                this.syntheticFocus = true;
                this.accountDropdown?.focus();
                return;
            }
        }

        if (event.target.matches(FOCUSABLE_NOT_DISABLED_INDEXABLE_SELECTORS)) {
            event.target.focus();
        }
    };

    onKeyDown = (event) => {
        if (event.code === 'Escape') {
            this.toggleGlobalMenu(false);
        }
    };

    onMouseDown = (event) => {
        this.focusedByMouse = getClosestEl(event.target, 'a, button');
        if (this.focusedByMouse) {
            setTimeout(() => {
                this.focusedByMouse = false;
            }, 0);
        }
    };

    /**
     * Check window scroll position, and conditonally apply classes
     */
    handleScroll() {
        if (this.hero) {
            // Apply CLASSNAMES.PAST_HERO if we have scrolled past the hero
            // Remove if otherwise
            const heroRect = this.hero.getBoundingClientRect();
            const heroBottom = heroRect.top + heroRect.height;

            this.setIsPastHero(heroBottom < 0);
        }
    }

    setIsActive(isActive) {
        document.body.classList.toggle(CLASSNAMES.ACTIVE, isActive);
        this.globalNavEl?.classList.toggle('-mobileActive', isActive);
        this.toggleBtn.setAttribute('aria-expanded', isActive);
        if (isActive) this.subNavs.keyControl.focusFirstMenuItem();
        else this.toggleBtn.focus();
        this.stateChangeListener();
    }

    setIsOpen(isOpen) {
        document.body.classList.toggle(CLASSNAMES.OPEN, isOpen);
        this.globalNavEl?.classList.toggle('-mobileOpened', isOpen);
    }

    /**
     * Affect DOM when page has been scrolled
     *
     * @param {bool} isPastHero
     */
    setIsPastHero(isPastHero) {
        if (isPastHero) {
            this.el.classList.add(CLASSNAMES.PAST_HERO); //TODO do cleanup after confirmation (see MSH-82312 "js-hero" string)
            clearTimeout(this.clickThroughAnimTimeout);
            // Hide fade after it has animated out
            this.clickThroughAnimTimeout = setTimeout(() => {
                this.el.classList.add(CLASSNAMES.ALLOW_CLICK_THROUGH);
            }, ANIMATION_DURATION);
        } else {
            clearTimeout(this.clickThroughAnimTimeout);
            this.el.classList.remove(CLASSNAMES.PAST_HERO, CLASSNAMES.ALLOW_CLICK_THROUGH);
        }
    }
}

const init = () => {
    const navEl = document.querySelector(configs.MAIN_NAV_SELECTOR);

    if (!navEl) {
        return;
    }

    // eslint-disable-next-line no-unused-vars
    const navBar = new Navbar(navEl);
};

export default init;
