import { reportError } from '@common/js/utils/error/error';
import { Subject } from '@common/js/utils/subject';
import { CurrencyBroadcast } from './currency-broadcast';
import { CurrencyBuilder } from './currency-builder';
import { CurrencyPersistence } from './currency-persistance';
import { CurrencyUrlObserver } from './currency-url-observer';
import type { VVCurrencies, VVCurrency, VVCurrencyState, VVCurrencySubject } from './types';
import { getEl } from '@utils/getEl';

export const CURRENCY_FALLBACK: VVCurrency = Object.freeze({ code: 'USD', label: 'United States Dollar', symbol: '$' });

class CurrencySubject extends Subject<VVCurrencyState> implements VVCurrencySubject {
    private broadcast = new CurrencyBroadcast(this.setByCode.bind(this));
    private builder = new CurrencyBuilder();
    private persistence: CurrencyPersistence;

    constructor(initialState: VVCurrencies | undefined, domain = '') {
        super({ availableCurrencies: [CURRENCY_FALLBACK], selectedCurrency: CURRENCY_FALLBACK });

        this.persistence = new CurrencyPersistence(domain);

        try {
            const state = this.builder.build(initialState);
            this.next(state);
            /** On page load, override the selected currency code cookie only if a user has selected a currency code. */
            if (this.persistence.isSaved()) {
                this.persistence.save(state.selectedCurrency.code);
            }
        } catch (error) {
            reportError(error);
        }
        this.subscribe(new CurrencyUrlObserver());
    }

    private setByCode(code: string): void {
        const state = this.get();
        const selectedCurrency = state.availableCurrencies.find((currency) => currency.code === code);
        if (!selectedCurrency) {
            throw new Error(`The currency doesn't exist in the list of available currencies.`);
        }
        this.next({ ...state, selectedCurrency });
    }

    getCode(): string {
        return this.get().selectedCurrency.code;
    }

    getSymbol(): string {
        return this.get().selectedCurrency.symbol;
    }

    getSymbolByCode(code?: string | null): string {
        return typeof code === 'string' && code.length > 0
            ? this.get().availableCurrencies.find((currency) => currency.code === code)?.symbol ?? ''
            : '';
    }

    select(code: string): void {
        this.setByCode(code);
        this.persistence.save(code);
        this.broadcast.emit(code);
    }
}

/**
 * Account is not using the right parameters to fetch the global nav, which includes the
 * currency selector script. Also, the global nav is being fetched in the server side, so
 * the VV_CURRENCIES variable is not being set.
 *
 * While we wait for Account to fix this defect, we are setting a fallback value
 * for the currency selector.
 */

const globalNav = getEl(document, '.js-GlobalNavigation');
const fallbackInitialState = JSON.parse(globalNav?.getAttribute('data-currencies') || '{}');
const fallbackDomain = globalNav?.getAttribute('data-domain') || '';

/** ACHTUNG!!! The Currency Subject should always be the singleton, don't create a new instance. */
export const currencySubject: VVCurrencySubject = new CurrencySubject(
    window.VV_CURRENCIES || fallbackInitialState,
    window.VV_DOMAIN || fallbackDomain
);
