import { getAllEl, getClosestEl, getEl } from '@utils/getEl';

interface GlobalMenu {
    control: HTMLElement | null;
    links: HTMLElement[];
    ref: Element;
}

/** @see https://www.w3.org/WAI/ARIA/apg/patterns/menubar/examples/menubar-navigation/ */
export class KeyControl {
    private menus: GlobalMenu[] = [];
    private isSubNavOpened: boolean;

    constructor(
        private triggerSubNavClose: () => void,
        private triggerSubNavOpen: (event: { target: Element }) => void,
        private classnames: Record<string, string> = {}
    ) {}

    private updateMenu(menuIndex = 0, linkIndex = 0, isOpened = false, callback?: () => void) {
        if (isOpened) {
            this.triggerSubNavOpen({ target: this.menus[menuIndex]?.ref });
            this.isSubNavOpened = true;
        } else {
            this.triggerSubNavClose();
            this.isSubNavOpened = false;
        }

        /**
         * We should make previous and next link focusable; otherwise, we won't have relatedTarget
         * in the `focusin` event.
         */
        this.menus[menuIndex].control?.setAttribute('tabindex', '0');
        this.menus[menuIndex].links[linkIndex]?.setAttribute('tabindex', '0');

        /** Focus should be performed before setting correct tabindex. */
        callback?.();

        this.menus.forEach((menu, index) => {
            const isTargetMenu = menuIndex === index;
            const isMenuFocusable = !isOpened && isTargetMenu;
            const menuTabIndex = isMenuFocusable ? '0' : '-1';
            menu.control?.setAttribute('tabindex', menuTabIndex);
            menu.links.forEach((link, index) => {
                const isLinkFocusable = isOpened && isTargetMenu && linkIndex === index;
                const linkTabIndex = isLinkFocusable ? '0' : '-1';
                link.setAttribute('tabindex', linkTabIndex);
            });
        });
    }

    private focusLink(menuIndex = 0, linkIndex = 0, isOpened = false) {
        this.updateMenu(menuIndex, linkIndex, isOpened, () => this.menus[menuIndex]?.links?.[linkIndex]?.focus());
    }

    private focusMenu(menuIndex = 0) {
        this.updateMenu(menuIndex, 0, false, () => this.menus[menuIndex]?.control?.focus());
    }

    public focusFirstMenuItem = () => {
        this.focusMenu(0);
    };

    public focusLastMenuItem = () => {
        this.focusMenu(this.menus.length - 1);
    };

    private parse(event: Event) {
        const menu = getClosestEl(event.target, this.classnames.ITEM);
        const menuIndex = this.menus.findIndex(({ ref }) => ref === menu);
        const isLinkFocused = getClosestEl(event.target, this.classnames.SUBNAV);
        const link = getClosestEl(event.target, this.classnames.SUBNAV_LINK, HTMLElement);
        const links = this.menus[menuIndex]?.links ?? [];
        const linkIndex = link ? links?.indexOf(link) : -1;
        return { isLinkFocused, linkIndex, linkNumb: links.length, menuIndex };
    }

    private getNextIndex(listLength: number, index: number) {
        return index < listLength - 1 ? index + 1 : 0;
    }

    private getPrevIndex(listLength: number, index: number) {
        return index === 0 ? listLength - 1 : index - 1;
    }

    private focusCurrentItem = (event: KeyboardEvent) => {
        event.preventDefault();
        const { isLinkFocused, menuIndex } = this.parse(event);
        if (isLinkFocused) {
            event.stopPropagation(); // Avoid closing the whole menu
            this.focusMenu(menuIndex);
        }
    };

    private focusCharSubMenu = (event: KeyboardEvent) => {
        const isChar = event.code.startsWith('Key');
        if (!isChar) {
            return;
        }
        const { isLinkFocused, linkIndex, menuIndex } = this.parse(event);
        const targetIndex = isLinkFocused ? linkIndex : menuIndex;
        const searchFrom = targetIndex + 1;
        const char = event.code.charAt(3);
        const labels = isLinkFocused
            ? this.menus[menuIndex].links.map((link) => link.innerText)
            : this.menus.map((menu) => menu.control?.innerText ?? '');
        const shiftIndex = labels.slice(searchFrom).findIndex((label) => label.startsWith(char));
        const nextIndex = searchFrom + shiftIndex;
        const isValid = nextIndex >= 0 && nextIndex > targetIndex;

        if (!isValid) {
            return;
        }

        event.preventDefault();
        if (isLinkFocused) {
            this.focusLink(menuIndex, nextIndex, true);
        } else {
            this.focusMenu(nextIndex);
        }
    };

    private focusFirstLink = (event: KeyboardEvent) => {
        const { isLinkFocused, menuIndex } = this.parse(event);
        if (!isLinkFocused) {
            event.preventDefault();
            this.focusLink(menuIndex, 0, true);
        }
    };

    private focusFirstMenu = (event: KeyboardEvent) => {
        event.preventDefault();
        this.focusMenu(0);
    };

    private focusLastMenu = (event: KeyboardEvent) => {
        event.preventDefault();
        this.focusMenu(this.menus.length - 1);
    };

    private focusNextItem = (event: KeyboardEvent) => {
        event.preventDefault();
        const { isLinkFocused, linkNumb, linkIndex, menuIndex } = this.parse(event);

        if (!isLinkFocused) {
            this.focusLink(menuIndex, 0, true);
            return;
        }

        const nextIndex = this.getNextIndex(linkNumb, linkIndex);
        this.focusLink(menuIndex, nextIndex, true);
    };

    private focusNextMenu = (event: KeyboardEvent, allowOut = false) => {
        const { isLinkFocused, menuIndex } = this.parse(event);
        const nextIndex = this.getNextIndex(this.menus.length, menuIndex);
        if (allowOut && nextIndex === 0) {
            return;
        }
        event.preventDefault();
        if (!isLinkFocused) {
            this.focusMenu(nextIndex);
            return;
        }

        this.focusLink(nextIndex, 0, true);
    };

    private focusPrevItem = (event: KeyboardEvent) => {
        event.preventDefault();
        const { isLinkFocused, linkNumb, linkIndex, menuIndex } = this.parse(event);

        if (!isLinkFocused) {
            return;
        }

        const nextIndex = this.getPrevIndex(linkNumb, linkIndex);
        this.focusLink(menuIndex, nextIndex, true);
    };

    private focusPrevMenu = (event: KeyboardEvent, allowOut = false) => {
        const { isLinkFocused, menuIndex } = this.parse(event);
        const nextIndex = this.getPrevIndex(this.menus.length, menuIndex);

        if (allowOut && nextIndex === this.menus.length - 1) {
            return;
        }
        event.preventDefault();
        if (!isLinkFocused) {
            this.focusMenu(nextIndex);
            return;
        }

        this.focusLink(nextIndex, 0, true);
    };

    private moveFocus = (event: KeyboardEvent): void => {
        if (event.shiftKey) {
            if (this.isSubNavOpened) {
                this.focusPrevItem(event);
            } else {
                this.focusPrevMenu(event, true);
            }
        } else {
            if (this.isSubNavOpened) {
                this.focusNextItem(event);
            } else {
                this.focusNextMenu(event, true);
            }
        }
    };

    private keyActions = {
        ArrowDown: this.focusNextItem,
        ArrowLeft: this.focusPrevMenu,
        ArrowRight: this.focusNextMenu,
        ArrowUp: this.focusPrevItem,
        End: this.focusLastMenu,
        Enter: this.focusFirstLink,
        Escape: this.focusCurrentItem,
        Home: this.focusFirstMenu,
        Space: this.focusFirstLink,
        Tab: this.moveFocus,
    };

    spyOn(element?: Element | undefined) {
        this.menus = getAllEl(element, this.classnames.ITEM).map((ref): GlobalMenu => {
            const control = getEl(ref, this.classnames.ITEM_LINK, HTMLElement);
            const links = getAllEl(ref, this.classnames.SUBNAV_LINK, HTMLElement);
            return { control, links, ref };
        });
        element?.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.repeat) {
                return;
            }
            const isChar = event.code.startsWith('Key');
            if (isChar) {
                this.focusCharSubMenu(event);
            } else {
                this.keyActions[event.code]?.(event);
            }
        });
        window.addEventListener('focusin', (event: FocusEvent) => {
            const isMenu = getClosestEl(event.target, this.classnames.ITEM);
            if (!isMenu) {
                this.updateMenu(0, -1, false);
            }
        });
    }
}
