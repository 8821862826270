import { Component } from '../../../../libs/component/component';
import { Navbar, CLASSNAMES } from '../../../cms/components/nav-bar';
import { GATracker } from '../../../../libs/ga-tracker';
import { RepositionBookingAccountElOnZoomLevelChange } from './reposition-booking-account';
import { globalNavSubject } from '../../../../common/js/components/global-navigation/global-navigation';
import { SkipToHashLink } from '../../../common/libs/skip-to-hash-link';
import { getEl } from '../../../../cms/js/utils/getEl';

export class GlobalNavigation extends Component {
    isHidden = false;
    repositionBookingAccount = new RepositionBookingAccountElOnZoomLevelChange();
    subject = globalNavSubject;

    onMount() {
        this.skipToMainHashLink = new SkipToHashLink(getEl(this.element.parentElement, '.js-SkipToMainContent'));
        this.skipToMainHashLink.mount();
        this.bar = this.element.querySelector('.GlobalNavigation__bar');
        // TODO: move logic from the old instance into current class
        this.oldInstance = new Navbar(this.bar, this.onScroll);
        this.onScroll();
        window.addEventListener('scroll', this.onScroll);
        /** The height update should happen only on scroll! */
        window.addEventListener('scroll', this.onHeightChange);

        // A temporary hack for "Account 1.0" booking flow app:
        // It is a hack because react components should NOT be moved by js like this - it can cause new component insertion by react in case "render" is called.
        // dropdown.js script's calls are redirecting only on public instances (for now doesn't work for author and local instances)
        const accountDropdowns = document.querySelectorAll('#booking-account:not(.WebMarketingPortal) .Popover');
        if (accountDropdowns.length) {
            const accountDropdown = accountDropdowns[0];
            document.querySelector('#booking-account.WebMarketingPortal')?.appendChild(accountDropdown);
            this.tmpHack_accountDropdown = accountDropdown;
        }

        // Module Scroller has a crutch in the development environment
        if (process.env.NODE_ENV === 'development') {
            setTimeout(this.onHeightChange, 4000);
        }

        document.body.classList.toggle('-nav-dark', this.element?.classList.contains('-dark'));
        this.highlighMenuItems();

        setTimeout(() => this.element?.classList.add('-animated'));

        const elementsToTrack = document.querySelectorAll('.js-NavGATracker');
        elementsToTrack?.forEach((element) => {
            element.addEventListener('click', this.handleClick);
        });

        this.repositionBookingAccount.watch();
    }

    onUnmount() {
        window.removeEventListener('scroll', this.onHeightChange);
        window.removeEventListener('scroll', this.onScroll);

        const accountDropdown = this.tmpHack_accountDropdown;
        if (accountDropdown) {
            document.querySelector('#booking-account:not(.WebMarketingPortal)')?.appendChild(accountDropdown);
            this.tmpHack_accountDropdown = null;
        }
        this.repositionBookingAccount.cleanUp();
        this.skipToMainHashLink.unmount();
    }

    onScroll = () => {
        const scrollY = window.scrollY;
        // do NOT use "window.scrollY" as the only condition: as far as there can present "AlertBanner__box" above the global navigation.
        // do not use "element.getBoundingClientRect().top <= 0" as the only condition: once 'GlobalNavigation__bar_fixed' is set it is never reset because that class has "position:fixed" and now "top:0" is there (for ".nav-bar2"), so isFixed=true once the class is set. That is true for case if there is no "AlertBanner__box" above.
        const stickTop = this.element.getBoundingClientRect().top <= 40 && scrollY > 40;

        // "down" means scrolling toward the page bottom content
        const scrollDown =
            typeof this.lastScrollY !== 'undefined' && // do not hide on refresh
            window.scrollY > this.lastScrollY;

        const subNavOpen = this.element.querySelector('.' + CLASSNAMES.NAV_SUBNAV_OPEN) != null; // it is about top navigation popup menu but NOT for mobile and NOT for tablet
        const active = document.body.classList.contains(CLASSNAMES.ACTIVE); // it is about mobile and tablet

        const isFixed = stickTop && !active; // means stick to the top
        // && !scrollDown it should be commented: the case is: top-nav is opened while page is scrolled up and down with mouse wheel over top-nav

        const isSubTabActive = this.element.classList.contains(CLASSNAMES.IS_SUBTAB_ACTIVE); // means that there was a click on SubTab

        this.isHidden =
            !active && // deny to hide menu for mobile and tablet
            !subNavOpen && // do not collapse opened bar if user does scrolling by wheel over opened bar (of background elements on a page)
            stickTop && // do not hide top-nav if there is "AlertBanner__box" above until sticky top-nav state.
            (isSubTabActive || // if the page is scrolling down, but there was a click on SubTab, then panel should be made hidden
                scrollDown); // if user does scroll-down the panel should be made hidden independently on stickyTop flag

        this.element.classList.toggle('-fixed', isFixed);
        this.element.classList.toggle('-hidden', this.isHidden);
        document.documentElement.style.setProperty(
            '--global-navigation-bar-height',
            this.isHidden ? 0 : `${this.bar.clientHeight}px`
        );

        // TODO: bind logic to .GlobaNavigation__bar.-fixed instead of
        // .GlobalNavigation__bar_fixed
        this.bar.classList.toggle('GlobalNavigation__bar_fixed', isFixed);
        this.bar.classList.toggle('is-nav-fixed', isFixed);
        // TODO reduces number of classes above

        this.lastScrollY = scrollY;
    };

    /** Other components should have an ability to track the Global Navigation visibility. */
    onHeightChange = () => {
        const { height } = this.bar.getBoundingClientRect();
        const hidden = this.isHidden;
        const detail = { height, hidden };
        this.subject.next((state) => ({ ...state, height, hidden }));
        const event = new CustomEvent('update', { detail });
        this.element.dispatchEvent(event);
    };

    highlighMenuItems() {
        const currentUrl = window.location.href;
        this.element?.querySelectorAll('.js-nav-bar2__item').forEach((item) => {
            const link = item.querySelector('.js-nav-bar2__link');
            const linkHref = link?.getAttribute('data-href');
            const subLink = Array.from(item.querySelectorAll('.js-GlobalNavigation__subLink')).find((subLink) => {
                const subLinkHref = subLink?.getAttribute('href');
                return subLinkHref && currentUrl.endsWith(subLinkHref);
            });
            subLink?.classList.add('-highlighted');
            const isLinkHighlighted = linkHref && currentUrl.includes(linkHref);
            if (!subLink && !isLinkHighlighted) return;
            link.classList.add('-highlighted');
        });
    }

    handleClick = (event) => {
        const navLink = event.target.closest('.js-NavGATracker');
        if (navLink) GATracker.push({ item: navLink });
    };
}
