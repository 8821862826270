import Cookie from 'js-cookie';

export class CurrencyPersistence {
    private readonly name = 'currencyCode';

    isSaved(): boolean {
        return !!Cookie.get(this.name);
    }

    save(code: string): void {
        Cookie.set(this.name, code);
    }
}
