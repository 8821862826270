import type { VoyageObservable } from './observable';
import deepEqual from 'fast-deep-equal';
import { Observable } from './observable';

export type VoyageMapper<T> = (value: T) => T;

export interface VoyageSubject<T> extends VoyageObservable<T> {
    next(value: T | VoyageMapper<T>): void;
}

export class Subject<T> extends Observable<T> implements VoyageSubject<T> {
    constructor(protected value: T) {
        super();
    }

    next(valueOrMap: T | VoyageMapper<T>): void {
        const newValue = this.checkMapper(valueOrMap) ? valueOrMap(this.value) : valueOrMap;
        if (deepEqual(newValue, this.value)) {
            return;
        }
        this.value = newValue;
        this.observers.next(this.value);
    }

    private checkMapper(valueOrMap: unknown): valueOrMap is VoyageMapper<T> {
        return typeof valueOrMap === 'function';
    }
}
