const basePx = 16;

export enum VIEWPORT_MIN {
    MOBILE = 320,
    MOBILE_L = 414,
    TABLET = 768,
    DESKTOP = 992,
    DESKTOP_L = 1200,
    DESKTOP_HD = 1270,
    DESKTOP_XL = 1600,
}

export enum VIEWPORT_MAX {
    MOBILE = 413,
    MOBILE_L = 767,
    TABLET = 991,
    DESKTOP = 1199,
    DESKTOP_L = 1269,
    DESKTOP_HD = 1599,
}

export function matchMinWidth(px: VIEWPORT_MIN): MediaQueryList {
    return window.matchMedia(`(min-width: ${px / basePx}rem)`);
}

export function matchMinHeight(px: VIEWPORT_MIN): MediaQueryList {
    return window.matchMedia(`(min-height: ${px / basePx}rem)`);
}

export function matchMinRectangle(width: VIEWPORT_MIN, height: number): MediaQueryList {
    return window.matchMedia(`(min-width: ${width / basePx}rem) and (min-height: ${height / basePx}rem)`);
}
